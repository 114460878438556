var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('Loading'):_c('div',{staticClass:"delivery"},[_c('b-card',[_c('div',{staticClass:"p-2 mb-1"},[_c('h1',{staticClass:"m-0 page-title"},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("delivery.EditShift") : _vm.$t("delivery.AddNewShift"))+" ")])]),_c('hr'),_c('validation-observer',{ref:"addShiftForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('delivery.AreaName'),"label-for":"Area Name"}},[_c('validation-provider',{attrs:{"name":"Area Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"custom-input",attrs:{"type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.area),callback:function ($$v) {_vm.$set(_vm.form_data, "area", $$v)},expression:"form_data.area"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('delivery.NumberOfDeliveryBoys'),"label-for":"Number of delivery boys"}},[_c('validation-provider',{attrs:{"name":"Number of delivery boys","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.max_delivery_boys),callback:function ($$v) {_vm.$set(_vm.form_data, "max_delivery_boys", $$v)},expression:"form_data.max_delivery_boys"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('delivery.StartTime')}},[_c('validation-provider',{attrs:{"name":"Start Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{attrs:{"dropleft":_vm.isRtl,"dropright":!_vm.isRtl,"state":errors.length > 0 ? false : null},on:{"context":_vm.OnContextStart},model:{value:(_vm.form_data.start_time),callback:function ($$v) {_vm.$set(_vm.form_data, "start_time", $$v)},expression:"form_data.start_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('delivery.EndTime')}},[_c('validation-provider',{attrs:{"name":"End Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{attrs:{"dropleft":_vm.isRtl,"dropright":!_vm.isRtl,"state":errors.length > 0 ? false : null},on:{"context":_vm.OnContextEnd},model:{value:(_vm.form_data.end_time),callback:function ($$v) {_vm.$set(_vm.form_data, "end_time", $$v)},expression:"form_data.end_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)],1),_c('b-form-checkbox',{attrs:{"switch":"","value":1,"unchecked-value":0},model:{value:(_vm.form_data.is_active),callback:function ($$v) {_vm.$set(_vm.form_data, "is_active", $$v)},expression:"form_data.is_active"}},[_vm._v(" "+_vm._s(_vm.$t("delivery.Activate"))+" ")]),_c('b-button',{staticClass:"primary-btn my-3",attrs:{"type":"submit","variant":"primary","block":"","disabled":_vm.isPending},on:{"click":_vm.handleSubmit}},[_c('span',[_vm._v(_vm._s(_vm.isEdit ? _vm.$t("delivery.SaveChanges") : _vm.$t("delivery.AddShift")))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }