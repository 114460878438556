<template>
  <Loading v-if="isLoading" />
  <div v-else class="delivery">
    <b-card>
      <div class="p-2 mb-1">
        <h1 class="m-0 page-title">
          {{ isEdit ? $t("delivery.EditShift") : $t("delivery.AddNewShift") }}
        </h1>
      </div>
      <hr />
      <validation-observer ref="addShiftForm">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('delivery.AreaName')"
                label-for="Area Name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Area Name"
                  rules="required"
                >
                  <b-form-input
                    type="text"
                    v-model="form_data.area"
                    :state="errors.length > 0 ? false : null"
                    class="custom-input"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('delivery.NumberOfDeliveryBoys')"
                label-for="Number of delivery boys"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Number of delivery boys"
                  rules="required"
                >
                  <b-form-input
                    type="number"
                    v-model="form_data.max_delivery_boys"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group :label="$t('delivery.StartTime')">
                <validation-provider
                  #default="{ errors }"
                  name="Start Time"
                  rules="required"
                >
                  <b-form-timepicker
                    :dropleft="isRtl"
                    :dropright="!isRtl"
                    @context="OnContextStart"
                    v-model="form_data.start_time"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('delivery.EndTime')">
                <validation-provider
                  #default="{ errors }"
                  name="End Time"
                  rules="required"
                >
                  <b-form-timepicker
                    :dropleft="isRtl"
                    :dropright="!isRtl"
                    @context="OnContextEnd"
                    v-model="form_data.end_time"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-checkbox
            switch
            v-model="form_data.is_active"
            :value="1"
            :unchecked-value="0"
          >
            {{ $t("delivery.Activate") }}
          </b-form-checkbox>
          <b-button
            type="submit"
            variant="primary"
            block
            @click="handleSubmit"
            class="primary-btn my-3"
            :disabled="isPending"
          >
            <span>{{
              isEdit ? $t("delivery.SaveChanges") : $t("delivery.AddShift")
            }}</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BRow,
  BCol,
  BFormTimepicker,
  BFormCheckbox,
  BInputGroup,
  BCard,
} from "bootstrap-vue";
import Loading from "@/components/shared/loading/loading.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@core/utils/validations/validations";

export default {
  name: "EditCoupon",
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormTimepicker,
    BFormCheckbox,
    BInputGroup,
    ValidationObserver,
    ValidationProvider,
    Loading,
    BCard,
  },
  data() {
    return {
      isLoading: true,
      isPending: false,
      current_id: null,
      isEdit: false,
      form_data: {
        area: null,
        max_delivery_boys: null,
        is_active: 0,
        start_time: null,
        end_time: null,
      },
      time_array: null,
    };
  },
  computed: {
    isRtl() {
      return this.$i18n.locale === "ar";
    },
  },
  watch: {
    $route(to, from) {
      if (to.name === "DeliveryShiftsAdd") {
        this.isEdit = false;
        this.form_data = {
          number: null,
          is_active: 0,
          start_time: null,
          end_time: null,
        };
      }
    },
  },

  created() {
    if (this.$route.params.id) {
      this.current_id = this.$route.params.id;
      this.getDeliveryData();
      this.isEdit = true;
    } else {
      this.isLoading = false;
    }
  },

  methods: {
    getDeliveryData() {
      this.isLoading = true;
      this.$http
        .get(`admin/shifts/${this.current_id}`)
        .then((res) => {
          if (res.status === 200) {
            const data = res.data.data;
            this.form_data.area = data.area;
            this.form_data.max_delivery_boys = data.max_delivery_boys;
            this.form_data.is_active = +data.is_active;
            this.time_array = this.formatDate(data.start_time, data.end_time);
            this.form_data.start_time = this.time_array[0];
            this.form_data.end_time = this.time_array[1];
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    formatDate(start, end) {
      const options = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };

      const parsedTime = new Date(`1970-01-01 ${start}`);
      const start_time24 = parsedTime.toLocaleTimeString([], options);

      const parsedTime_end = new Date(`1970-01-01 ${end}`);
      const end_time24 = parsedTime_end.toLocaleTimeString([], options);

      return [start_time24, end_time24];
    },

    handleAddSubmit() {
      this.$refs.addShiftForm.validate().then((success) => {
        if (success) {
          if (this.start_hours < 10) {
            this.start_hours = "0" + this.start_hours;
          }
          if (this.start_minutes < 10) {
            this.start_minutes = "0" + this.start_minutes;
          }
          if (this.end_hours < 10) {
            this.end_hours = "0" + this.end_hours;
          }
          if (this.end_minutes < 10) {
            this.end_minutes = "0" + this.end_minutes;
          }
          this.form_data.start_time =
            this.start_hours + ":" + this.start_minutes;
          this.form_data.end_time = this.end_hours + ":" + this.end_minutes;
          this.isPending = true;
          const formData = new FormData();
          for (const key in this.form_data) {
            formData.append(key, this.form_data[key]);
          }

          this.$http
            .post(`admin/shifts`, formData)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.$helpers.makeToast(
                  "success",
                  this.$t("delivery.added_successfully"),
                  res.data.message
                );
                setTimeout(() => {
                  this.$router.push({ name: "DeliveryShiftsList" });
                }, 500);
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);
              this.isPending = false;
            })
            .finally(() => {
              this.isPending = false;
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
    handleEditSubmit() {
      this.$refs.addShiftForm.validate().then((success) => {
        if (success) {
          if (this.start_hours < 10) {
            this.start_hours = "0" + this.start_hours;
          }
          if (this.start_minutes < 10) {
            this.start_minutes = "0" + this.start_minutes;
          }
          if (this.end_hours < 10) {
            this.end_hours = "0" + this.end_hours;
          }
          if (this.end_minutes < 10) {
            this.end_minutes = "0" + this.end_minutes;
          }
          this.form_data.start_time =
            this.start_hours + ":" + this.start_minutes;
          this.form_data.end_time = this.end_hours + ":" + this.end_minutes;
          this.isPending = true;
          const formData = new FormData();
          for (const key in this.form_data) {
            formData.append(key, this.form_data[key]);
          }

          this.$http
            .post(`admin/shifts/${this.current_id}?_method=put`, formData)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.$helpers.makeToast("success", res.data.message);
                setTimeout(() => {
                  this.$router.push({ name: "DeliveryShiftsList" });
                }, 1000);
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);
              this.isPending = false;
            })
            .finally(() => {
              this.isPending = false;
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
    handleSubmit() {
      if (this.isEdit) {
        return this.handleEditSubmit();
      }
      return this.handleAddSubmit();
    },
    OnContextStart(ctx) {
      ctx.formatted = "HH:ii";
      (this.start_hours = ctx.hours), (this.start_minutes = ctx.minutes);
    },
    OnContextEnd(ctx) {
      ctx.formatted = "H:i";
      (this.end_hours = ctx.hours), (this.end_minutes = ctx.minutes);
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
